import { FormEvent, useEffect, useState } from 'react'
import { atualizarCurriculo, dadosCandidato } from '../../services/bancoTalentos';
import { listarDadosCandidato } from '../../interfaces/bancoTalentos';
import { Button, Loader } from '../styles';
import Notificacoes from '../Notificacoes';
import fotoPerfil from "../../assets/img/fotoPerfil.png";
import editarIcon from '../../assets/img/editar.png'
import './curriculo.css'

function Curriculo() {

  const [candidato, setCandidato] = useState<listarDadosCandidato>()
  const [modalSobre, setModalSobre] = useState(false)
  const [dadosSobreMim, setDadosSobreMim] = useState(candidato?.sobreMim || "")
  const [processando, setProcessando] = useState(false)
  const [erro, setErro] = useState(false)
  const [curriculoAtualizado, setCurriculoAtualizado] = useState(false)

  const fecharModalSobre = () => {
    setDadosSobreMim(candidato?.sobreMim || "")
    setModalSobre(false)
  }

  const salvarDadosModal = (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    atualizarDados(e)
  }

  const atualizarDados = async (e: FormEvent) => {
    e.preventDefault()

    const payload = {
      sobreMim: dadosSobreMim
    }

    try {

      const resposta = await atualizarCurriculo(payload)

      if (resposta.status === 200) {
        setDadosSobreMim(resposta.data.sobreMim)
        setProcessando(false)
        fecharModalSobre()
        setCurriculoAtualizado(true)
      }

      return setTimeout(() => {
        setCurriculoAtualizado(false)
      }, 5000)
    }


    catch (error: any) {
      setProcessando(false)
      setErro(true)

      if (error.response.status === 400) {
        setErro(true)
        return setTimeout(() => {
          setErro(false)
        }, 5000)
      }

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  const listarDadosCandidato = async () => {

    try {

      const resposta = await dadosCandidato()
      setCandidato(resposta.data)
      setDadosSobreMim(resposta.data.sobreMim)

    } catch (error) {

      setProcessando(false)
      setErro(true)

      return setTimeout(() => {
        setErro(false)
      }, 5000)
    }
  }

  useEffect(() => {
    listarDadosCandidato()
  }, [])

  return (
    <section className='bodyCurriculo'>
      {curriculoAtualizado && <Notificacoes cor='sucesso' texto='Dados Salvos com Sucesso' />}
      {erro && <Notificacoes cor='erro' texto='Falha ao Processar a Solicitação' />}
      <section className='containerCurriculo flex-column'>
        <div className='div-header-foto'>
          <section className='headerCurriculo'>
            <span className='container-fotoPerfil left'>
              <img src={fotoPerfil} alt="" className='fotoPerfil' />
            </span>
            <span className='container-header flex-column'>
              <span className='nome-candidato'>
                <h2>{candidato && candidato.nomeCompleto}</h2>
                <label>Tecnologia da Informação</label>
              </span>
              <span className='area-contato'>
                <span>
                  <h3>Telefone:</h3>
                  <label>{candidato && candidato.telefone}</label>
                </span>
                <span>
                  <h3>E-mail</h3>
                  <label>{candidato && candidato.email}</label>
                </span>
              </span>
            </span>
          </section>
        </div>
        <section className='mainCurriculo flex-row'>
          <aside className='left hidden-responsive'>
            <hr />
            <span className='endereco'>
              <h3>Endereço</h3>
              <label>{candidato?.cidade && candidato?.estado ? candidato.cidade + "/" + candidato.estado : "Adicione um endereço"}</label>
            </span>
            <hr />
          </aside>
          <section className='main-content-curriculo'>
            <div className='curriculoAbout'>
              <div className='container-sobreMim-editar'>
                <h2>SOBRE MIM</h2>
                <img src={editarIcon} alt="" className='iconEditarModal' onClick={() => setModalSobre(true)} />
              </div>
              <p>{dadosSobreMim !== "" ? dadosSobreMim : "Fale um pouco sobre você"}</p>
            </div>
          </section>
        </section>
      </section>

      {modalSobre &&
        <div className='fundoModal'>
          <section className="containerModalCentro">
            <form className="formModal" onSubmit={salvarDadosModal}>
              <section>
                <span className="novoContainerTitulo">
                  <h1>Sobre mim</h1>
                </span>
              </section>
              <section className="sectionModal">
                <textarea name="sobreMim" rows={15} placeholder='Fale um pouco sobre você' className='textAreaSobreMim' onChange={(e) => { setDadosSobreMim(e.target.value) }} maxLength={500} >{dadosSobreMim}</textarea>
              </section>
              <div className="botoesContainer">
                <Button isLoading={processando} cor="cancelar" type='button' isCancelar={true} onClick={fecharModalSobre}>Cancelar</Button>
                <Button isLoading={processando} cor="continuar" type='submit' isCancelar={false}>
                  {!processando && 'Salvar'}
                  {processando && <Loader isLoading={processando} />}
                </Button>
              </div>
            </form>
          </section>
        </div>
      }
    </section>
  )
}

export default Curriculo