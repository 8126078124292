import { FormEvent, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { cadastrarCandidato, auth, recuperarSenha, novaSenha } from '../../services/bancoTalentos'
import { Button, Loader } from '../styles'
import { telefoneMascara, cpfMascara, validarCpfCnpj } from '../../helpers'
import Notificacoes from '../Notificacoes'
import './auth.css'
import '../../styles/modal.css'

function Auth () {

    const navigate = useNavigate()
    const location = useLocation()

    const { hash } = location

    const campanha = hash.substring(1) || null

    const [nomeCompleto, setNomeCompleto] = useState("")
    const [telefone, setTelefone] = useState("")
    const [email, setEmail] = useState("")
    const [cpf, setCpf] = useState("")
    const [senha, setSenha] = useState('')
    const [token, setToken] = useState('')
    const [confirmarSenha, setConfirmarSenha] = useState("")
    const [verificarSenha, setVerificarSenha] = useState(false)
    const [acessarPortal, setAcessarPortal] = useState(true)
    const [cadastrarPortal, setCadastrarPortal] = useState(false)
    const [esqueciSenha, setEsqueciSenda] = useState(false)
    const [dadosInvalidos, setDadosInvalidos] = useState(false)
    const [cpfInvalido, setCpfInvalido] = useState(false)
    const [candidadoExistente, setCandidatoExistente] = useState(false)
    const [usuarioNaoEncontrato, setUsuarioNaoEncontrato] = useState(false)
    const [tokenEmail, setTokenEmail] = useState(false)
    const [tokenInvalido, setTokenInvalido] = useState(false)
    const [tokenExpirado, setTokenExpirado] = useState(false)
    const [dadosCadastrados, setDadosCadastrados] = useState(false)
    const [processando, setProcessando] = useState(false)
    const [confirmarNovaSenha, setConfirmarNovaSenha] = useState(false)
    const [erro, setErro] = useState(false)


    const cancelar = () => {

        return navigate('/')
    }

    const realizarCadastro = () => {

        setAcessarPortal(false)
        setCadastrarPortal(true)
        setEsqueciSenda(false)

        return
    }

    const realizarLogin = () => {

        setAcessarPortal(true)
        setCadastrarPortal(false)

        return
    }

    const resetarSenha = () => {

        setAcessarPortal(false)
        setEsqueciSenda(true)

        return
    }

    const voltarLogin = () => {

        setAcessarPortal(true)
        setEsqueciSenda(false)
        setTokenEmail(false)

        return
    }

    const validarCPF = () => {

        const validar = validarCpfCnpj(cpf)

        if (validar === false) {
            setCpfInvalido(true)
            setCpf('')

            return setTimeout(() => {
                setCpfInvalido(false)
            }, 5000)
        }
    }

    const conferirSenha = () => {

        if (senha !== confirmarSenha) {
            setVerificarSenha(true)
            setSenha('')
            setConfirmarSenha('')

            return setTimeout(() => {
                setVerificarSenha(false)
            }, 5000)
        }

    }

    const novoCandidato = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)


        const payload = {
            nomeCompleto,
            cpf,
            email,
            telefone,
            senha,
            campanha
        }

        try {

            const resposta = await cadastrarCandidato(payload)

            if (resposta.status === 201) {
                setDadosCadastrados(true)
                setProcessando(false)

                setCadastrarPortal(false)
                setAcessarPortal(true)

                return setTimeout(() => {
                    setDadosCadastrados(false)
                }, 5000)
            }

        } catch (error: any) {

            setProcessando(false)

            if (error.response.status === 500) {

                setErro(true)

                return setTimeout(() => {
                    setErro(false)
                }, 5000)
            }

            if (error.response.status === 409) {
                setCandidatoExistente(true)

                return setTimeout(() => {
                    setCandidatoExistente(false)
                }, 5000)
            }

        }
    }

    const login = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                usuario: email,
                senha
            }

            const resposta = await auth(payload)

            if (resposta.status === 200) {
                const data = new Date()
                const validadeToken = new Date(data.getTime() + 60 * 60 * 4 * 1000)

                const token = resposta.data.token
                document.cookie = `authCandidato=${token}; expires=true ${validadeToken.toUTCString()}`

                setProcessando(false)

                return navigate('/portal-candidato')
            }

        } catch (error: any) {

            setProcessando(false)

            if (error.response.status === 400) {
                setDadosInvalidos(true)
                setProcessando(false)

                return setTimeout(() => {
                    setDadosInvalidos(false)
                }, 5000)
            }

            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)
        }
    }


    const redefinirSenha = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                usuario: email
            }

            const resposta = await recuperarSenha(payload)

            if (resposta.status === 200) {
                setEsqueciSenda(false)
                setProcessando(false)
                setTokenEmail(true)

                return
            }

        } catch (error: any) {

            setProcessando(false)

            if (error.response.status === 404) {
                setUsuarioNaoEncontrato(true)
                setProcessando(false)

                return setTimeout(() => {
                    setUsuarioNaoEncontrato(false)
                }, 5000)
            }

            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)

        }
    }


    const definirNovaSenha = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                usuario: email,
                senha,
                token
            }

            const respota = await novaSenha(payload)

            if (respota.status === 200) {
                setProcessando(false)
                setTokenEmail(false)
                setAcessarPortal(true)
                setConfirmarNovaSenha(true)

                return setTimeout(() => {
                    setConfirmarNovaSenha(false)
                }, 5000)

            }

        } catch (error: any) {

            setProcessando(false)

            if (error.response.status === 404) {
                setUsuarioNaoEncontrato(true)
                setProcessando(false)

                return setTimeout(() => {
                    setUsuarioNaoEncontrato(false)
                }, 5000)
            }

            if (error.response.status === 401) {
                setTokenInvalido(true)
                setProcessando(false)

                return setTimeout(() => {
                    setTokenInvalido(false)
                }, 5000)
            }

            if (error.response.status === 405) {
                setTokenExpirado(true)
                setProcessando(false)

                return setTimeout(() => {
                    setTokenExpirado(false)
                }, 5000)
            }

            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)

        }
    }

    return (
        <div className='fundoModal'>
            {erro && <Notificacoes cor='erro' texto='Falha ao Processar a Solicitação' />}
            {dadosInvalidos && <Notificacoes cor='erro' titulo='Dados Inválidos. ' texto='Tente Novamente' />}
            {cpfInvalido && <Notificacoes cor='erro' titulo='CPF Incorreto. ' texto='Tente Novamente' />}
            {candidadoExistente && <Notificacoes cor='erro' texto='Já existe um usuário com os dados informados' />}
            {dadosCadastrados && <Notificacoes cor='sucesso' titulo='Dados Salvo com Sucesso. ' texto='Agora você já pode realizar o login' />}
            {verificarSenha && <Notificacoes cor='erro' titulo='Senhas não Conferem. ' texto='Tente Novamente' />}
            {confirmarNovaSenha && <Notificacoes cor='sucesso' titulo='Nova senha cadastrada ' texto='com sucesso' />}
            {tokenInvalido && <Notificacoes cor='erro' titulo='Token Inválido. ' texto='Verifique os dados informados' />}
            {tokenExpirado && <Notificacoes cor='erro' titulo='Token Expirado. ' texto='Refaça o processo' />}
            {usuarioNaoEncontrato && <Notificacoes cor='erro' texto='Usuário não Encontrato' />}
            <section className='containerModalCentro'>
                {acessarPortal && <form className='formModal' onSubmit={login}>
                    <section>
                        <span className='novoContainerTitulo'>
                            <h1>Portal do candidato</h1>
                        </span>
                    </section>

                    <section>
                        <span>
                            <h1>Login</h1>
                            <p>Ainda não tem Conta? <strong className='btnCadastroLogin' onClick={realizarCadastro}>Cadastre-se</strong></p>
                        </span>
                    </section>
                    <section>
                        <span>
                            <label htmlFor="nomeUsario">Usuário</label>
                            <input
                                id='nomeUsario'
                                type="email"
                                value={email}
                                placeholder='Digite o e-mail'
                                onChange={(e) => setEmail(e.target.value)}
                                required />
                        </span>

                        <span>
                            <label htmlFor="senhaUsario">Senha</label>
                            <input
                                id='senhaUsario'
                                type='password'
                                value={senha}
                                placeholder='Digite a sua senha'
                                onChange={(e) => setSenha(e.target.value)}
                                required />
                        </span>
                    </section>

                    <section>
                        <span className='recuperarSenhaContainer'>
                            <p onClick={resetarSenha}>Esqueci Minha Senha</p>
                        </span>
                    </section>

                    <span className="botoesContainer">
                        <Button isCancelar={true} type='button' cor='cancelar' isLoading={processando} onClick={cancelar}>Cancelar</Button>
                        <Button isCancelar={false} type='submit' cor='continuar' isLoading={processando}>
                            {!processando && 'Continuar'}
                            {processando && <Loader isLoading={processando} />}
                        </Button>
                    </span>
                </form>}

                {cadastrarPortal && <form className='formModal' onSubmit={novoCandidato}>
                    <section>
                        <span className='novoContainerTitulo'>
                            <h1>Portal do candidato</h1>
                        </span>
                    </section>

                    <section>
                        <span>
                            <h1>Cadastre-se</h1>
                            <p>Já possui uma conta? <strong className='btnCadastroLogin' onClick={realizarLogin}>Login</strong></p>
                        </span>
                    </section>
                    <section>
                        <span>
                            <label htmlFor="nomeUsario">Nome Completo</label>
                            <input
                                id='nomeUsario'
                                type='text'
                                value={nomeCompleto}
                                placeholder='Digite seu nome completo'
                                onChange={(e) => setNomeCompleto(e.target.value)}
                                required />
                        </span>

                        <span>
                            <label htmlFor="cpfUsario">CPF</label>
                            <input
                                id='cpfUsario'
                                type='text'
                                value={cpf}
                                minLength={16}
                                maxLength={16}
                                onBlur={validarCPF}
                                placeholder='000.000.000 - 00'
                                onChange={(e) => setCpf(cpfMascara(e.target.value))}
                                required />
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="emailUsario">E-mail</label>
                            <input
                                id='emailUsario'
                                type="email"
                                value={email}
                                placeholder='Digite o e-mail'
                                onChange={(e) => setEmail(e.target.value)}
                                required />
                        </span>

                        <span>
                            <label htmlFor="telefoneUsario">Telefone</label>
                            <input
                                id='telefoneUsario'
                                type='text'
                                value={telefone}
                                pattern="^(\([0-9]{2}\))\s([0-9]{1})?\.([0-9]{4})\s-\s([0-9]{4})"
                                minLength={18}
                                maxLength={18}
                                placeholder='(00) 0.0000 - 0000'
                                onChange={(e) => setTelefone(telefoneMascara(e.target.value))}
                                required />
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="senhaUsario">Senha</label>
                            <input
                                id='senhaUsario'
                                type="password"
                                value={senha}
                                placeholder='Digite sua senha'
                                onChange={(e) => setSenha(e.target.value)}
                                required />
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="confirmarSenhaUsario">ConfirmarSenha</label>
                            <input
                                id='confirmarSenhaUsario'
                                type='password'
                                value={confirmarSenha}
                                onBlur={conferirSenha}
                                placeholder='Confirme sua senha'
                                onChange={(e) => setConfirmarSenha(e.target.value)}
                                required />
                        </span>
                    </section>

                    <div className='containerPolicitaTermo'>
                        <p>Ao enviar os dados você está de acordo com a nossa <Link to="/politicaprivacidade">POLÍTICA DE PRIVACIDADE</Link> e os nossos <Link to="/termosuso">TERMOS DE USO</Link>.</p>
                    </div>

                    <span className="botoesContainer">
                        <Button isCancelar={true} type='button' cor='cancelar' isLoading={processando} onClick={cancelar}>Cancelar</Button>
                        <Button isCancelar={false} type='submit' cor='continuar' isLoading={processando}>
                            {!processando && 'Enviar Dados'}
                            {processando && <Loader isLoading={processando} />}
                        </Button>
                    </span>
                </form>}

                {esqueciSenha && <form className='formModal' onSubmit={redefinirSenha}>
                    <section>
                        <span className='novoContainerTitulo'>
                            <h1>RECUPERAR SENHA</h1>
                        </span>
                    </section>

                    <section>
                        <span>
                            <h1>Login</h1>
                            <p>Ainda não tem Conta? <strong className='btnCadastroLogin' onClick={realizarCadastro}>Cadastre-se</strong></p>
                        </span>
                    </section>
                    <section>
                        <span>
                            <label htmlFor="nomeUsario">Usuário</label>
                            <input
                                id='nomeUsario'
                                type="email"
                                value={email}
                                placeholder='Digite o e-mail'
                                onChange={(e) => setEmail(e.target.value)}
                                required />
                        </span>
                    </section>

                    <span className="botoesContainer">
                        <Button isCancelar={true} type='button' cor='cancelar' isLoading={processando} onClick={voltarLogin}>Voltar</Button>
                        <Button isCancelar={false} type='submit' cor='continuar' isLoading={processando}>
                            {!processando && 'Continuar'}
                            {processando && <Loader isLoading={processando} />}
                        </Button>
                    </span>
                </form>}

                {tokenEmail && <form className='formModal' onSubmit={definirNovaSenha}>
                    <section>
                        <span className='novoContainerTitulo'>
                            <h1>RECUPERAR SENHA</h1>
                        </span>
                    </section>

                    <section>
                        <span>
                            <h1>Login</h1>
                            <p>Ainda não tem Conta? <strong className='btnCadastroLogin' onClick={realizarCadastro}>Cadastre-se</strong></p>
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="senhaUsario">Senha</label>
                            <input
                                id='senhaUsario'
                                type="password"
                                value={senha}
                                placeholder='Digite sua senha'
                                onChange={(e) => setSenha(e.target.value)}
                                required />
                        </span>

                        <span>
                            <label htmlFor="confirmarSenhaUsario">ConfirmarSenha</label>
                            <input
                                id='confirmarSenhaUsario'
                                type='password'
                                value={confirmarSenha}
                                onBlur={conferirSenha}
                                placeholder='Confirme sua senha'
                                onChange={(e) => setConfirmarSenha(e.target.value)}
                                required />
                        </span>
                    </section>

                    <section>
                        <span>
                            <label htmlFor="tokenEmail">Token</label>
                            <input
                                id='tokenEmail'
                                type="number"
                                value={token}
                                minLength={4}
                                maxLength={4}
                                placeholder='Digite o token recebido por e-mail'
                                onChange={(e) => setToken(e.target.value)}
                                required />
                        </span>
                    </section>

                    <span className="botoesContainer">
                        <Button isCancelar={true} type='button' cor='cancelar' isLoading={processando} onClick={voltarLogin}>Voltar</Button>
                        <Button isCancelar={false} type='submit' cor='continuar' isLoading={processando}>
                            {!processando && 'Continuar'}
                            {processando && <Loader isLoading={processando} />}
                        </Button>
                    </span>
                </form>}
            </section>
        </div>
    )
}

export default Auth