import baseApiGesttor from "../api"
import { loginPayload, cadastrarCandidatoPayload, recuperarSenhaPayload, redefinirSenhaPayload, atualizarCurriculoPayload } from "../../interfaces/bancoTalentos"

export function auth(payload: loginPayload) {
    return baseApiGesttor.post('/bancoTalentos/login', payload)
}

export function cadastrarCandidato(payload: cadastrarCandidatoPayload) {
    return baseApiGesttor.post('/bancoTalentos', payload)
}

export function recuperarSenha(payload: recuperarSenhaPayload) {
    return baseApiGesttor.post('/bancoTalentos/recuperarSenha', payload)
}

export function novaSenha(payload: redefinirSenhaPayload) {
    return baseApiGesttor.post('/bancoTalentos/redefinirSenha', payload)
}

export function dadosCandidato() {
    return baseApiGesttor.get(`/dadosCandidato`)
}

export function atualizarCurriculo(payload: atualizarCurriculoPayload) {
    return baseApiGesttor.patch(`/atualizarCurriculo`, payload)
}